import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

//import images
import features from '../assets/images/features-img/img-1.png';
import features2 from '../assets/images/features-img/img-2.png';
import instrucao from '../assets/images/abcm-instrucao.png';
import rev1 from '../assets/images/rev1.png';
import rev2 from '../assets/images/rev2.png';
import rev3 from '../assets/images/rev3.png';
import doc from '../assets/files/Template_ICIPE2024_Word_Paper.doc'
import tex from '../assets/files/Template_ICIPE2024_LaTeX.zip'

export default class Publications extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section bg-about bg-light-about bg-light" id="publications">
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <div className="title-heading mb-5 mt-5">
                                    <h3 className="text-dark mb-1 fw-light text-uppercase">Publications</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col lg={12}>
                                <div className="features-content">

                                    <p className="text-dark f-16 mb-3">	<i className="pe-7s-angle-right-circle text-primary f-16"></i>  Abstracts and papers will be submitted through the electronic system of the Brazilian Society of Mechanical Sciences and Engineering – ABCM, which can be accessed at: </p>
                                    <a href="https://www.sistema.abcm.org.br" target="_blank"
                                       className="btn btn-custom f-16 mb-3">www.sistema.abcm.org.br</a>
                                    <p className="text-dark f-16 mb-3">	<i className="pe-7s-angle-right-circle text-primary f-16"></i>  The event ICIPE-2024 should be selected at the ABCM abstract/paper submission system.</p>
                                    <p className="text-dark f-16 mb-3">	<i className="pe-7s-angle-right-circle text-primary f-16"></i> First-time users need to “sign-up” to the ABCM system before submitting abstracts.</p>
                                    <p className="text-dark f-16 mb-3">	<i className="pe-7s-angle-right-circle text-primary f-16"></i> All authors need to be registered in the ABCM system for the submission of abstracts or papers.</p>

                                    <img src={instrucao} alt="" className="mb-3 tns-lazy-img" />


                                    <p className="text-danger fw-bold f-16 mb-3">	<i className="pe-7s-angle-right-circle text-primary f-16"></i>	Although the deadline for abstract submission was December 22, 2023, the conference organization might accomodate few more submissions. If you are interested in submitting an abstract, please contact one of the members of the conference organizations as soon as possible.</p>
                                    <p className="text-danger fw-bold f-16 mb-3">	<i className="pe-7s-angle-right-circle text-primary f-16"></i>	Authors of accepted abstracts are welcome to submit full papers by January 15, 2024. Submitted papers will then undergo peer evaluation by at least two qualified reviewers.</p>
                                    <p className="text-danger fw-bold f-16 mb-3">	<i className="pe-7s-angle-right-circle text-primary f-16"></i>	Authors are required to follow the available Word and Latex templates for the preparation of their papers:</p>

                                    <p className="text-dark f-14 mb-1">	<i className="pe-7s-star text-primary f-12"></i> <a href={doc}> Template ICIPE2024 Word</a></p>
                                    <p className="text-dark f-14 mb-3">	<i className="pe-7s-star text-primary f-12"></i> <a href={tex}>Template ICIPE2024 Latex</a></p>



                                    <p className="text-dark f-16 mb-3">	<i className="pe-7s-angle-right-circle text-primary f-16"></i>	The ICIPE-2024 Proceedings containing accepted papers of registered participants will be published on a flash disk. Copyright Transfer will not be required for publication of accepted papers in the ICIPE-2024 Proceedings.</p>
                                    <p className="text-dark f-16 mb-3">	<i className="pe-7s-angle-right-circle text-primary f-16"></i>	At least one author needs to be registered in ICIPE-2024 for the paper to be included in the Conference Program and published in the Proceedings.</p>
                                    <p className="text-dark f-16 mb-3">	<i className="pe-7s-angle-right-circle text-primary f-16"></i>	The Book of Abstracts and the Conference Proceedings will be distributed to registered participants during the Conference.</p>
                                    <p className="text-dark f-16 mb-3">	<i className="pe-7s-angle-right-circle text-primary f-16"></i>	Authors of papers effectively presented in the Conference might be invited to submit expanded and revised versions of their ICIPE-2024 papers for additional review and possible publication in special issues of the following journals:</p>
                                    <p className="text-dark f-14 mb-1">	<i className="pe-7s-star text-primary f-12"></i> <a href="https://www.tandfonline.com/action/journalInformation?journalCode=uhte20">Heat Transfer Engineering (2022 Impact Factor: 2.3)</a></p>
                                    <p className="text-dark f-14 mb-1">	<i className="pe-7s-star text-primary f-12"></i> <a href="https://www.begellhouse.com/journals/computational-thermal-sciences.html">Computational Thermal Sciences (2022 Impact Factor: 1.5)</a></p>
                                    <p className="text-dark f-14 mb-3">	<i className="pe-7s-star text-primary f-12"></i> <a href="https://www.springer.com/journal/40430"> Journal of the Brazilian Society of Mechanical Sciences and Engineering (2022 Impact Factor: 2.2)</a></p>

                                </div>



                            </Col>
                            <Col lg={3}>
                                <img src={rev1} alt="" className="tns-lazy-img" />
                            </Col>
                            <Col lg={3}>
                                <img src={rev2} alt="" className=" tns-lazy-img" />
                            </Col>
                            <Col lg={3}>
                                <img src={rev3} alt="" className=" tns-lazy-img"  />
                            </Col>


                            {/*<Col lg={6}>*/}
                            {/*    <div className="features-img mt-32">*/}
                            {/*        <img src={features} alt="" className="img-fluid mx-auto d-block" />*/}
                            {/*    </div>*/}
                            {/*</Col>*/}
                        </Row>

                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
