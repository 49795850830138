import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

//import images
import features from '../assets/images/features-img/img-1.png';
import features2 from '../assets/images/features-img/img-2.png';
import features3 from '../assets/images/features-img/img-3.png';

export default class Scientific extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section bg-about bg-light-about bg-light" id="scientific">
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <div className="title-heading mb-5 mt-5">
                                    <h3 className="text-dark mb-1 fw-light text-uppercase">SCIENTIFIC COMMITTEE</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col lg={6}>
                                <div className="features-content">
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Alain Kassab</span>, USA,	University of Central Florida</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Aleksey Nenarokomov</span>, Russia,	Moscow Aviation Institute</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Ana Leonor Silvestre</span>, Portugal,	Universidade Técnica de Lisboa</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Anatoly G. Yagola</span>,	Russia,	Lomonosov Moscow State University</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Andrzej Nowak</span>,	Poland,	Silesian University of Technology</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Antonio J. Silva Neto</span>,	Brazil,	Universidade do Estado do Rio de Janeiro</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Axel Osses</span>,	Chile,	Universidad del Chile</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Barbara Kaltenbacher</span>,	Austria,	Alpen-Adria-Universität Klagenfurt</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Benjamin Rémy</span>,	France,	Lorraine University</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Bernd Hofmann</span>,	Germany,	Chemnitz University of Technology</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Colin Fox</span>,	New Zeland,	University of Otago</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Daniel Lesnic</span>,	UK,	University of Leeds</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Daniel Watzenig</span>,	Austria,	Graz University of Technology</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Denis Maillet</span>,	France,	Lorraine University</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Domingos Rade</span>,	Brazil,	Instituto Tecnológico de Aeronáutica</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Eduardo Divo</span>,	USA,	Embry-Riddle Aeronautical University</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Fabio Bozzoli</span>, Italy,	Parma University</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Fabrice Rigollet</span>, France,	Aix-Marseille Université</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Filippo De Monte</span>,	Italy,	University of L'Aquila</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">George Dulikravich</span>, USA,	Florida International University</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Gloria Frontini</span>,	Argentina,	Universidad Nacional de Mar del Plata</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Guillermo Eliçabe</span>,	Argentina,	Universidad Nacional de Mar del Plata</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Ireneusz Szczygiel</span>,	Poland, Silesian University of Technology</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Jari Kaipio</span>, Finland, University of Eastern Finland</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Jay I. Frankel</span>,	USA, New Mexico State University</p>


                                </div>
                            </Col>

                            <Col lg={6}>
                                <div className="features-content mt-32">
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Jean-Christophe Batsale</span>,	France,	Université de Bordeaux</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Jean-Laurent Gardarein</span>,	France,	Aix-Marseille Université</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Jean-Luc Battaglia</span>,	France,	Université de Bordeaux</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Jorge Zubelli</span>, United Arab Emirates & Brazil,	Khalifa University & IMPA</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Kirk D. Dolan</span>,	USA,	Michigan State University</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Kyle Daun</span>,	Canada,	University of Waterloo</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Liliane Barichello</span>,	Brazil,	Federal University of Rio Grande do Sul</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Liviu Marin</span>,	Romania,	University of Bucharest</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Markus Haltmeier</span>,	Austria,	University of Innsbruck</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Michael V. Klibanov</span>,	USA,	University of North Carolina at Charlotte</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Nuno Martins</span>,	Portugal,	Nova University</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Olivier Quemener</span>, France, Université d'Evry Val d'Essonne</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Otmar Scherzer</span>,	Austria,	Universitat Wien</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Philippe Le Masson</span>,	France,	Université Bretagne Sud</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Raul Lima</span>,	Brazil, Universidade de São Paulo</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Regina Almeida</span>,	Brazil, Laboratório Nacional de Computação Científica</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Ryszard Bialecki</span>, Poland,	Silesian University of Technology</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Sara Rainieri</span>,	Italy,	Parma University</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Shiro Kubo</span>,	Japan,	Osaka University</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Simon Arridge</span>,	UK,	University College London</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Uri Ascher</span>,	Canada,	University of British Columbia</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Ville Kolehmainen</span>,	Finland,	University of Eastern Finland</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Yann Favennec</span>,	France,	Nantes Université</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Yassine Rouizi</span>,	France,	Université d'Évry Val d'Essonne</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Zbigniew Bulinski</span>,	Poland,	Silesian University of Technology</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold">Zhihai Xiang</span>,	China,	Tsinghua University Beijing</p>
                                </div>

                            </Col>

                            {/*<Col lg={6}>*/}
                            {/*    <div className="features-img mt-32">*/}
                            {/*        <img src={features} alt="" className="img-fluid mx-auto d-block" />*/}
                            {/*    </div>*/}
                            {/*</Col>*/}
                        </Row>

                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
