import React, { Component } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Speakers2 from "./Speakers2";

export default class Info extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section bg-about bg-light-about bg-light" id="info">
                    <Container>

                        <Row>
                            <Col lg={12}>
                                <div className="title-heading mb-5 mt-5">
                                    <h3 className="text-dark mb-1 fw-light text-uppercase">IMPORTANT INFORMATION</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>

                            <Col lg={12}>

                                <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">Conference Program:</h4>
                                <ul className="text-dark f-16 mb-1">
                                    <li>The ICIPE-2024 Program at a Glance can be found at the conference website.</li>
                                    <li>The detailed Program will be available during this week.</li>
                                </ul>

                                <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">Oral Presentations:</h4>
                                <ul className="text-dark f-16 mb-1">
                                    <li>A computer will be available for your presentation. You are kindly asked to load
                                        your presentation to this computer in advance, to avoid delays in the technical
                                        sessions.
                                    </li>
                                    <li>The time slot of 20 minutes for oral presentations also includes possible
                                        questions from the audience. Please make your presentation in about 15 minutes
                                        to allow questions from the audience.
                                    </li>
                                </ul>

                                <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">Poster Presentations:</h4>
                                <ul className="text-dark f-16 mb-1">
                                    <li>Authors should hang their posters at the beginning of the conference and have
                                        them removed when the conference is finished. Posters should always be
                                        available, to give high visibility to the presented works.
                                    </li>
                                    <li>Authors of poster presentations will be able to interact with other participants
                                        during the whole conference, but it is particularly asked that they be at the
                                        poster site during coffee-breaks for questions/explanations.
                                    </li>
                                    <li>There is no specific format for the posters, but it is suggested that an A0 size
                                        (841x1189 mm) be used. Posters should be prepared to be suspended as indicated
                                        in the figure below.
                                    </li>
                                    <li>It will not be possible to print posters at the conference site.</li>
                                </ul>


                                <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">Meals:</h4>
                                <ul className="text-dark f-16 mb-1">
                                    <li>A welcome drink will be offered by the pool side in Hotel Atlântico Búzios on
                                        Sunday, June 23, from 19:00 to 20:00.
                                    </li>
                                    <li>The conference banquet will be held in a restaurant within a walking distance
                                        from Hotel Atlântico Búzios, on Wednesday (June 26) from 19:30 until 22:00. The
                                        conference banquet is included in the registration fee for conference
                                        participants.
                                    </li>
                                    <li>Coffee-breaks and lunches in Hotel Atlântico Búzios are included in the
                                        registration fee for conference participants.
                                    </li>
                                    <li>Tickets for meals of accompanying persons, including the conference banquet,
                                        will be available for purchase at the conference registration desk.
                                    </li>
                                </ul>

                                <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">Travel Information:</h4>
                                <ul className="text-dark f-16 mb-1">
                                    <li>Airports in Rio de Janeiro are served by UBER and taxis. Inside the airport, you
                                        will find taxi company counters, where you can buy a taxi ticket at a fixed
                                        price to your destination.
                                    </li>
                                    <li>Do not take unofficial taxis or transportation, which might be offered to you at
                                        the airport.
                                    </li>
                                    <li>UBER is very popular in Rio de Janeiro for transportation.
                                    </li>
                                    <li>Brazilian phone cards can be bought at newspaper stands at the airport and
                                        around the cities of Rio de Janeiro and Búzios.
                                    </li>
                                    <li>The use of sunscreen and mosquito repellent is recommended.</li>
                                </ul>

                                <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">Currency Information:</h4>
                                <ul className="text-dark f-16 mb-1">
                                    <li>The Brazilian currency is named “Reais” (R$ or BRL). For the conversion of the
                                        Brazilian Reais to US Dollars (USD), please consult
                                        https://www.bloomberg.com/markets/currencies/americas.
                                    </li>
                                    <li>International ATM Machines and money exchange companies are available at the
                                        airport. Usually, US Dollars and Euros are preferred for exchange to Reais. You
                                        are recommended to exchange money only at these official companies or at your
                                        hotel.
                                    </li>
                                    <li>The use of actual money is becoming less often in Brazil, while credit and debit
                                        cards are accepted almost everywhere.
                                    </li>
                                </ul>

                                <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">Buses between Rio de
                                    Janeiro and Búzios:</h4>
                                <ul className="text-dark f-16 mb-1">
                                    <li>You should have provided all the information requested for the free
                                        transportation, which will be provided by the conference organization between
                                        Rio de Janeiro and Búzios.
                                    </li>
                                    <li>The bus from Rio de Janeiro to Hotel Atlântico Búzios is scheduled to depart at
                                        14:00, on June 23, from Windsor Excelsior Copacabana Hotel (Av. Atlântica, 1800
                                        – Copacabana). You should be at this meeting point before 13:30. This bus will
                                        pass by the Rio de Janeiro International Airport (GIG – Arrivals) after it
                                        leaves Copacabana, to collect participants who arrive on June 23. There will be
                                        no other stops in the trip from Rio de Janeiro to Búzios.
                                    </li>
                                    <li>The bus offered by the conference organization will depart from Hotel Atlântico
                                        Búzios on June 28 around 13:00, after the ICIPE Conference finishes. The exact
                                        time of departure will be communicated during the conference. From Búzios, the
                                        bus will go to the Rio de Janeiro International Airport (GIG) and then to
                                        Windsor Excelsior Copacabana Hotel. There will be no other stops in the trip
                                        from Búzios to Rio de Janeiro.
                                    </li>
                                    <li>The conference organization will not be responsible for your transportation if
                                        you have not provided the requested information, or if you arrive late at
                                        Windsor Excelsior Copacabana Hotel. The conference organization will not be
                                        responsible for any delays in the bus trips between Rio de Janeiro and Búzios,
                                        due to traffic or any other causes beyond our control.
                                    </li>
                                </ul>

                                <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">Dress Code During the
                                    Conference:</h4>
                                <ul className="text-dark f-16 mb-1">
                                    <li>None! 😊
                                    </li>
                                </ul>

                                <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">We are looking forward to seeing you soon in Búzios!</h4>
                                    <p className=" f-16 mt-10">

                                        ICIPE-2024 Organizing Committee<br/>
                                        icipe2024@gmail.com<br/>
                                        https://icipe2024.org/</p>


                            </Col>
                        </Row>


                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
