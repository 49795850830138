import React, {Component} from 'react'
import {Col, Container, Row} from 'reactstrap';

import doc2 from '../assets/files/Award Application_US Participants_ICIPE2024.docx';


export default class Transport extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="transport">
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <div className="title-heading mb-5 mt-5">
                                    <h3 className="text-dark mb-1 fw-light text-uppercase">Important Information About
                                        Transportation</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col lg={12}>
                                <div className="features-content">

                                    <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">Bus from Rio de Janeiro
                                        to Búzios:</h4>
                                    <p className="text-dark f-16 mb-1">The bus offered by the conference organization
                                        for transportation from Rio de Janeiro to Hotel Atlântico Búzios is scheduled to
                                        depart at 14:00, on June 23. The meeting point is:</p>
                                    <p className="text-dark f-16 mb-1">Windsor Excelsior Copacabana Hotel</p>
                                    <p className="text-dark f-16 mb-1">Av. Atlântica, 1800 - Copacabana</p>
                                    <p className="text-dark f-16 mb-1">You should be at the meeting point
                                        before 13:30.</p>
                                    <p className="text-dark f-16 mb-1"> The bus will pass by the Rio de
                                        Janeiro International Airport (GIG – Arrivals) after it leaves
                                        the meeting point, to collect participants who arrive on June
                                        23. There will be no other stops in the trip from Rio de Janeiro
                                        to Búzios.</p>

                                    <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">Bus
                                        from Búzios to
                                        Rio de Janeiro:</h4>
                                    <p className="text-dark f-16 mb-1">The bus offered by the conference organization
                                        will depart from
                                        Hotel Atlântico Búzios on June 28 around 12:30, after the ICIPE
                                        Conference finishes. The exact time of departure will be
                                        communicated as soon as the Final Program is consolidated. From
                                        Búzios, the bus will go to the Rio de Janeiro International
                                        Airport (GIG) and then to Windsor Excelsior Copacabana Hotel.
                                        There will be no other stops in the trip from Búzios to Rio de
                                        Janeiro.</p>

                                    <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">Requested
                                        Information for Bus Transportation:</h4>
                                    <p className="text-dark f-16 mb-1">The following information is requested for the
                                        organization of
                                        the bus transportation. Document number is mandatory, as
                                        requested by the traffic authority.</p>

                                    <p className="text-dark f-16 mb-1">Full Name:</p>
                                    <p className="text-dark f-16 mb-1">Phone number:</p>
                                    <p className="text-dark f-16 mb-1">E-mail:</p>
                                    <p className="text-dark f-16 mb-1">RG Number (for Brazilians):</p>
                                    <p className="text-dark f-16 mb-1"> Passport Number (for
                                        Foreigners):</p>
                                    <p className="text-dark f-16 mb-1"> Pick-up point:</p>
                                    <p className="text-dark f-16 mb-1"> _____ Windsor Excelsior Copacabana
                                        Hotel</p>
                                    <p className="text-dark f-16 mb-1"> _____ Rio de Janeiro
                                        International Airport</p>

                                    <p className="text-dark f-16 mb-1">The above
                                        information is also required for
                                        accompanying
                                        persons.</p>

                                    <p className="text-custom f-16 mb-1">You need to
                                        send this information by email to
                                        receptivo@nztravel.tur.br before May 31.</p>

                                    <p className="text-dark f-16 mb-1">The
                                        conference organization will not be responsible
                                        for your
                                        transportation if you arrive late at the meeting
                                        point or if
                                        the
                                        above information is not provided by the deadline of
                                        May 31.</p>
                                    <p className="text-dark f-16 mb-1">The
                                        conference organization will not be responsible for
                                        any
                                        delays
                                        in the bus trips between Rio de Janeiro and Búzios,
                                        due to
                                        traffic or any other cause beyond our control.</p>

                                </div>
                            </Col>
                        </Row>

                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
