import React, { Component } from 'react'
import { Col, Container, Row } from 'reactstrap';

import doc2 from '../assets/files/Award Application_US Participants_ICIPE2024.docx';


export default class Travel extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="travel">
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <div className="title-heading mb-5 mt-5">
                                    <h3 className="text-dark mb-1 fw-light text-uppercase">Travel information</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col lg={12}>
                                <div className="features-content">
                                        <p className="text-dark f-16 mb-1">The main destination for international travelers is the Rio de Janeiro International Airport (GIG), named after Antonio Carlos Jobim – the great Bossa Nova composer and singer.  Rio de Janeiro is served with everyday direct flights from many cities around the world and is a major hub for South America. The Santos Dumont Airport (SDU), conveniently located in downtown Rio de Janeiro, also offers local connections with national flights mainly from the city of São Paulo.</p>

                                    <p className="text-dark f-16 mb-1">The city of Búzios, where the ICIPE-2024 venue is located, is about 2.5 hours from Rio de Janeiro by car/bus, depending on traffic. Free bus transportation will be provided for registered conference participants. The bus will leave Rio de Janeiro to Búzios in the afternoon of June 23. Free bus transportation will also be provided from Búzios back to Rio de Janeiro in the afternoon of June 28, after the conference ends. Registered conference participants will be later notified about pick-up locations and times for free transportation.</p>
                                    <p className="text-dark f-16 mb-1">Be aware that we might face heavy traffic in our trip back to Rio de Janeiro in the afternoon of June 28. You should take into account possible delays in this trip, to schedule your flight leaving from Rio de Janeiro after the conference.</p>
                                    <p className="text-dark f-16 mb-1">Registered conference participants who cannot take the provided free buses will need to cover the costs of their own transportation.</p>


                                    <p className="text-dark f-16 mb-1">Depending on the time of your flight arrival/departure, you may need to stay one or more nights in Rio de Janeiro. It will be a great opportunity to visit the “<a href="https://visitrio.com.br/" target="_blank" rel="noopener noreferrer">Marvelous City</a>”. In addition to postcards recognized in the four corners of the planet, such as the Christ The Redeemer Statue and the Sugar Loaf hill, Rio de Janeiro has in its essence a busy life, where you can find excellent restaurants, bars, nightclubs, theaters, museums and much more. Rio de Janeiro has good weather, blue sky, sunshine and smiles on the faces of those who can enjoy this truly wonderful city.</p>
                                        <p className="text-dark f-16 mb-1">Rio de Janeiro occupies an area of 1,261 km2, with a population of about 6 million people. The city has as one of its greatest virtues, the warmth and hospitality that its people welcome visitors. With its ample infrastructure of tourist services, Rio de Janeiro is ranked among the top destinations in the world for cultural events and commercial, technical and scientific conferences and exhibitions. Its exuberant natural resources include 90 km of beaches and the Tijuca National Park, which is the largest urban forest in the world. Ipanema Beach has been selected in 2024 as the second best beach in the world by the <a href="https://www.lonelyplanet.com/articles/worlds-best-beaches" target="_blank" rel="noopener noreferrer">Lonely Planet travel site</a>. Internationally renowned by the Bossa Nova music <a href="https://youtu.be/WuenyQ4NCQE?si=-MXTlWjFREAtJecy" target="_blank" rel="noopener noreferrer">Girl from Ipanema</a>, Ipanema Beach is 3 km long, with crystalline waters and beautiful sunsets.</p>

                                        <p className="text-dark f-16 mb-1">The G20 Leaders' Summit is scheduled for November 2024 in Rio de Janeiro, with the presence of the leaders of the 19 member countries, plus the African Union and the European Union. The Group of Twenty (G20) is the main forum for international economic cooperation. It plays an important role in defining and strengthening global architecture and governance on all major international economic issues.</p>

                                        <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">VISA INFORMATION</h4>
                                    <p className="text-dark f-16 mb-1"> Brazil applies visa policies by reciprocity, according to the visitor's nationality. To check whether you need a visa to Brazil, <a href="https://www.gov.br/mre/pt-br/embaixada-helsinque/consular-services/nationalities-that-require-a-visa-and-visa-exemptions" target="_blank" rel="noopener noreferrer">please visit</a>.</p>
                                        <p className="text-dark f-16 mb-1"> If a visa is required for you to enter Brazil, you need to start the visa application process as soon as possible. You should verify the documentation requested for visa application in the Brazilian Consulate General of your country. Please contact any member of the Organizing Committee if you need an invitation letter for your visa application.  Due to security reasons, invitation letters can only be provided to registered participants.</p>

                                        <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">CURRENCY INFORMATION</h4>
                                    <p className="text-dark f-16 mb-1">The Brazilian currency is named Reais (R$ or BRL). For the conversion of the Brazilian Reais to US Dollars (USD), please consult <a href="https://www.bloomberg.com/markets/currencies/americas" target="_blank" rel="noopener noreferrer">https://www.bloomberg.com/markets/currencies/americas</a>. The use of actual money is becoming less often in Brazil, while credit and debit cards are accepted almost everywhere.</p>

                                        <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">TRAVEL AGENCY</h4>
                                    <p className="text-dark f-16 mb-1"> The official Travel Agency of the ICIPE-2024 conference is NZ TRAVEL. For hotel/transportation reservation and information, please send email messages to <a href="mailto:reservas@nztravel.tur.br">reservas@nztravel.tur.br</a> and <a href="mailto:receptivo@nztravel.tur.br">receptivo@nztravel.tur.br</a>. You may also call the following numbers: +55 21 99135 5531 or +55 21 99856 9800.</p>


                                    <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">TRAVEL GRANT OPPORTUNITY FOR U.S. PARTICIPANTS</h4>

                                    <p className="text-dark f-16 mb-1"> The U.S. National Science Foundation has awarded funding through the Florida Institute of Technology to support the participation of U.S. researchers at ICIPE 2024. Support is available only to researchers working in the U.S., irrespective of nationality.  If you are a student or faculty/researcher from a U.S. institution working on inverse problems, you should apply for partial reimbursement for your conference travel costs under this award. The deadline for submitting applications is April 12, 2024. You may find the application at <a href={doc2} target="_blank" rel="noopener noreferrer">this link</a>. Questions can be sent to Dr. Hamid Najafi at hnajafi@fit.edu.</p>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
