import React, { Component, Suspense } from "react";
import Scientific from "../../component/Scientific";
import Publications from "../../component/Publications";
import Registration from "../../component/Registration";
import Transport from "../../component/Transport";
import Travel from "../../component/Travel";
import Painel from "../../component/Painel"
import Info from "../../component/Info"

// Importing Section
const Navbar = React.lazy(() => import("../../component/Navbar/NavBar"));

const Section = React.lazy(() => import("../Layout7/Section"));
const About = React.lazy(() => import("../../component/About"));
const Services = React.lazy(() => import("../../component/Services"));
const Features = React.lazy(() => import("../../component/Features"));
const Pricing = React.lazy(() => import("../../component/Pricing"));
const Clients = React.lazy(() => import("../../component/Clients"));
const Blog = React.lazy(() => import("../../component/Blog"));
const Contact = React.lazy(() => import("../../component/Contact"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));

// import { Spinner } from "reactstrap";

class Layout7 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        // { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "about", navheading: "About"},
        { id: 3, idnm: "scientific", navheading: "SCIENTIFIC COMMITTEE" },
        { id: 4, idnm: "publications", navheading: "Abstracts Publications" },
        { id: 5, idnm: "dates", navheading: "IMPORTANT DATES" },
        { id: 6, idnm: "lecturers", navheading: "Keynote Lecturers" },
        { id: 7, idnm: "registration", navheading: "REGISTRATION FEES" },
        { id: 8, idnm: "travel", navheading: "TRAVEL AND TRANSPORTATION" },
        { id: 9, idnm: "venue", navheading: "VENUE" },
      ],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "navbar-light navbar-custom sticky sticky-dark",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky navbar-light navbar-custom sticky sticky-dark", imglight: false });
    } else {
      this.setState({ navClass: "navbar-light navbar-custom sticky sticky-dark", imglight: true });
    }
  };

  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />



          {/* Importing Section */}
          <Section />

          <Info/>

          {/* Importing About */}
          <About />

          {/*Importing Contact*/}
          <Contact />

          {/* Importing Services */}
          <Services />

          {/* Importing Features */}
          <Features />



          {/*Importing Blog*/}
          <Blog />

          {/* Importing Features */}
          <Scientific />

          {/* Importing Features */}
          <Publications />



          {/*Importing Clients*/}
          <Clients />




          {/* Importing Pricing */}
          <Pricing />
          <Painel/>




          {/* Importing Features */}
          <Registration />

          {/* Importing Pricing */}
          <Travel />


          <Transport />



        {/* Importing Footer */}
          <Footer />

        </Suspense>
      </React.Fragment>
    );
  }
}
export default Layout7;
