import React, { Component } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Speakers2 from "./Speakers2";

export default class Painel extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section bg-about bg-light-about bg-light" id="painel">
                    <Container>

                        <Row>
                            <Col lg={12}>
                                <div className="title-heading mb-5 mt-5">
                                    <h3 className="text-dark mb-1 fw-light text-uppercase">Panel discussion</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>

                            <Col lg={12}>

                                <h4 className="text-custom mt-4 mb-1 fw-bold text-uppercase">Inverse Problems and
                                    Artificial Intelligence: methodological issues</h4>
                                <p className="text-primary f-16 mb-1"> Roundtable moderators: Fabrice Rigollet and Denis Maillet</p>
                                    <p className="text-dark f-16 mb-1"> For a few years, Artificial intelligence(AI) is becoming unavoidable in many sectors of society and in various sciences. One of its fields, Machine Learning (ML) is focused on the development of statistical algorithms that can learn from data and generalize them to unseen data in order to achieve tasks without explicit instructions.</p>
                                        <p className="text-dark f-16 mb-1"> In a logical way, scientists, engineers and students trying to solve inverse problems have started to use the tools developed in ML, and such is the case at this ICIPE conference. These tools are very often based on models based on Artificial Neural Networks (ANNs), a specific architecture that is able to relate input data to unknow outputs in a direct mode.</p>
                                            <p className="text-dark f-16 mb-1"> However, in order for the structural
                                                parameters of these models (biases and weights) to be estimated in a right way, it is very useful to add physical informations related to the problem at stake during their construction, that is to use Physical-Informed Neural Networks (PINNs).</p>
                                                <p className="text-dark f-16 mb-1"> This topic, which is presently
                                                    exploding, uses a terminology and methodologies that may differ from the ones previously used in the community of inverse problems.</p>
                                                    <p className="text-dark f-16 mb-1"> So, the aim of this panel
                                                        discussion is to identify methodological links, but also differences, between ML tools and the corresponding more classical tools used in our community, in the direct and inverse modes (model reduction, model identification, inversion of sources, regularization,…).</p>
                                                        <p className="text-dark f-16 mb-1"> The panel will be organized
                                                            around very short presentations by some authors of the ML papers, aimed at presenting the objectives, assumptions, direct and inverse modelling tools, data and/or measurements, as well as the limitations and perspectives of their studies. These presentations will be preceded by a short introduction and followed by a roundtable discussion about them.</p>


                            </Col>
                        </Row>



                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
