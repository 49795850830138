import React, { Component } from 'react'
import { Col, Container, Row } from 'reactstrap';
import payment01 from '../assets/images/payment01.png';
import payment02 from '../assets/images/payment02.png';
import payment03 from '../assets/images/payment03.png';
import instrucao from "../assets/images/abcm-instrucao.png";
const data = [
    { category: "Students (Early-bird)", fee: 1560},
    { category: "Professionals (Early-bird)", fee: 3120},
    { category: "Students", fee: 2000},
    { category: "Professionals", fee: 3500},
]
export default class Registration extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section bg-about bg-light-about bg-light" id="registration">
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <div className="title-heading mb-5 mt-5">
                                    <h3 className="text-dark mb-1 fw-light text-uppercase">Registration Fees</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col lg={12}>
                                <div className="features-content">

                                    <div>
                                        <table className="table-bordered table-primary w-100">
                                            <tr>
                                                <th>CATEGORY</th>
                                                <th className="text-center" >BRL</th>
                                            </tr>
                                            {data.map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{val.category}</td>
                                                        <td className="text-center" >{val.fee}</td>
                                                    </tr>
                                                )
                                            })}
                                        </table>
                                        <strong>BRL: BRAZILIAN REAIS</strong>
                                    </div>
                                    <p></p>
                                    <p className="text-dark f-16 mb-1">	<i className="pe-7s-angle-right-circle text-primary f-16"></i>  For the conversion of the Brazilian Reais (BRL) to US Dollars (USD), please consult <a href="https://www.bloomberg.com/markets/currencies/americas">bloomberg.com</a></p>
                                    <p className="text-dark f-16 mb-1">	<i className="pe-7s-angle-right-circle text-primary f-16"></i>  Early-bird rates apply to registration fees paid before <strong>May 30,2024</strong>.</p>
                                    <p className="text-dark f-16 mb-1">	<i className="pe-7s-angle-right-circle text-primary f-16"></i>  Brazilian participants affiliated with ABCM or SBMAC are entitled to a 50% reduction of the above values.</p>
                                </div>
                            </Col>

                            {/*<Col lg={6}>*/}
                            {/*    <div className="features-img mt-32">*/}
                            {/*        <img src={features} alt="" className="img-fluid mx-auto d-block" />*/}
                            {/*    </div>*/}
                            {/*</Col>*/}
                        </Row>

                        <Row className="mt-4">
                            <Col lg={12}>
                                <div className="features-content">
                                    <h4 className="text-primary mb-1 fw-light text-uppercase">PAYMENT</h4>
                                    <p className="text-dark f-16 mb-1">	<i className="pe-7s-angle-right-circle text-primary f-16"></i>  Payments will be processed by ABCM – Brazilian Society of Mechanical Engineering and Sciences (Associação Brasileira de Engenharia e Ciências Mecânicas).</p>
                                    <p className="text-dark f-16 mb-1">	<i className="pe-7s-angle-right-circle text-primary f-16"></i> You can pay the registration fee either by PayPal or by bank transfer to ABCM´s checking account in Brazil, by following the instructions provided below.</p>
                                    <p className="text-dark f-16 mb-1">	<i className="pe-7s-angle-right-circle text-primary f-16"></i> In the case of cancellation after the payment, the registration fee will be reimbursed but $100 US Dollars will be deducted as a cancellation fee.</p>
                                    <p className="text-dark f-16 mb-1">	<i className="pe-7s-angle-right-circle text-primary f-16"></i> If you have any questions or need special assistance, please send an email to the Symposium Treasurers, Prof. Cesar Pacheco and Prof. Marcelo Colaço (icipe2024@gmail.com). </p>
                                </div>
                            </Col>
                        </Row>


                        <Row className="mt-4">
                            <Col lg={12}>
                                <div className="features-content">
                                    <h4 className="text-custom mb-1 fw-bold text-uppercase"> 1. Payment by PayPal:</h4>

                                    <p className="f-16 mb-1">Your registration fee can be paid by PayPal to <span className="fw-bold">ABCM – Associação Brasileira de Engenharia e Ciências Mecânicas</span>, email address <span className="fw-bold">abcm@abcm.org.br</span>.</p>
                                    <p className="f-16 mb-1"><span className="fw-bold text-custom">1.1.</span>	Access the address <a href="https://www.sistema.abcm.org.br/" target="_blank">https://www.sistema.abcm.org.br/</a>, select the event ICIPE 2024 and log in using the username and password you choose when submitting your paper/abstract (see figure below).</p>
                                    <img src={payment01} alt="" className="mb-3 shadow tns-lazy-img" />
                                    <p className="f-16 mb-1"><span className="fw-bold text-custom">1.2.</span>	On the left menu, choose Registration (your menu might look different from the one on the figure below).</p>
                                    <img src={payment02} alt="" className="mb-3 shadow tns-lazy-img" />
                                    <p className="f-16 mb-1"><span className="fw-bold text-custom">1.3.</span>	Select the payment method as follows (see figure below). Please verify if the system identified you in the right category.</p>
                                    <p className="f-16 mb-1">“Boleto” – used by some Brazilian authors. Not applicable to foreigners.</p>
                                    <p className="f-16 mb-1">“Empenho” – used by some Brazilian authors. Not applicable to foreigners.</p>
                                    <p className="text-danger fw-bold f-16 mb-1">“Paypal” – select this to use Paypal.</p>
                                    <img src={payment03} alt="" className="mb-3 shadow tns-lazy-img" />
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold text-custom">1.4.</span>	Click on “Register”.</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold text-custom">1.5.</span>	Follow the PayPal instructions. The amount will correspond to the one on the previous screen.</p>
                                    <p className="text-dark f-16 mb-1"><span className="fw-bold text-custom">1.6.</span>	Please email us <a href="mailto:icipe2024@gmail.com">(icipe2024@gmail.com)</a> the RECEIPT, together with your FULL NAME AND PAPER NUMBER.</p>



                                </div>
                            </Col>
                        </Row>


                        <Row className="mt-4">
                            <Col lg={12}>
                                <div className="features-content">
                                    <h4 className="text-custom mb-1 fw-bold text-uppercase">2.Payment by Bank Transfer:</h4>
                                    <p className="f-16 mb-1">Your registration fee can be paid by bank transfer to <span className="fw-bold">ABCM – Associação Brasileira de Engenharia e Ciências Mecânicas</span>, email address <span className="fw-bold">abcm@abcm.org.br</span>. </p>
                                    <p className="f-16 mb-1"> <span className="fw-bold text-custom">2.1.</span>	Proceed to your bank and make the wire transfer using the following data. The fee must be checked on the conference website. If you need an invoice, please email us (icipe2024@gmail.com) and include the data you need on it.</p>
                                    <p className="text-danger fw-bold f-16 mb-1">
                                        BANCO DO BRASIL<br/>
                                        SWIFT CODE: BRASBRRJBHE<br/>
                                        IBAN: BR2400000000029750002118742C1<br/>
                                        AGENCY NUMBER: 2975-0<br/>
                                        AGENCY NAME: Avenida Rio Branco<br/>
                                        ACCOUNT NUMBER: 211.874-2<br/>
                                        ACCOUNT OWNER: Associação Brasileira de Engenharia e Ciências Mecânicas - ABCM (CNPJ: 83.431.593/0001-78)<br/>
                                    </p>

                                    <p className="f-16 mb-1"><span className="fw-bold text-custom">2.2.</span>	Please email us <span className="fw-bold text-custom">(icipe2024@gmail.com)</span> the RECEIPT, together with your FULL NAME AND PAPER NUMBER.</p>



                                </div>
                            </Col>
                        </Row>

                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
